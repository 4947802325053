import {
  APP_INITIALIZER,
  LOCALE_ID,
  ErrorHandler,
  NgModule,
} from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';

import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { AppComponentModules } from './modules/app-component.module';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { SidenavComponent } from './components/layout/sidenav/sidenav.component';

import { PopUpComponent } from './components/shared/pop-up/pop-up.component';
import {
  NgHttpCachingConfig,
  NgHttpCachingModule,
  NgHttpCachingStrategy,
} from 'ng-http-caching';
import { CommonModule, registerLocaleData } from '@angular/common';
import { SnAutoCompleteComponent } from './components/shared/sn-auto-complete/sn-auto-complete.component';
import { AutoCompleteComponent } from './components/shared/auto-complete/auto-complete.component';
import { WarningpopUpComponent } from './components/shared/warningpop-up/warningpop-up.component';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { ProducerInquiriesComponent } from './views/producer-inquiries/producer-inquiries.component';
import localeDe from '@angular/common/locales/de';
import { AddButtonComponent } from './components/matchmaking/add-button/add-button.component';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { ContractTermBannerComponent } from './components/shared/contract-term-banner/contract-term-banner.component';
import { SaveAwkDialogComponent } from './components/awk/save-awk-dialog/save-awk-dialog.component';
import { TicketFormComponent } from './components/admin/ticket-form/ticket-form.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SelectNaceSearchComponent } from './components/awk/select-nace-search/select-nace-search.component';
import { CreateEditDialogComponent } from './components/shared/create-edit-dialog/create-edit-dialog.component';
import { ServiceWorkerModule } from '@angular/service-worker';

const ngHttpCachingConfig: NgHttpCachingConfig = {
  cacheStrategy: NgHttpCachingStrategy.DISALLOW_ALL,
};

registerLocaleData(localeDe);

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: environment.kcRealmUrl,
        realm: environment.kcRealm,
        clientId: 'wastics_frontend',
      },
      initOptions: {
        onLoad: 'login-required',
      },
      shouldAddToken: () => true,
      bearerExcludedUrls: ['/assets'],
      shouldUpdateToken(request) {
        return !request.headers.get('token-update') === false;
      },
    });
}

@NgModule({
  declarations: [
    AppComponent,
    PopUpComponent,
    SaveAwkDialogComponent,
    TicketFormComponent,
    SelectNaceSearchComponent,
  ],
  imports: [
    HttpClientModule,
    NgHttpCachingModule.forRoot(ngHttpCachingConfig),
    AppComponentModules,
    KeycloakAngularModule,
    SnAutoCompleteComponent,
    AutoCompleteComponent,
    WarningpopUpComponent,
    ProducerInquiriesComponent,
    SidenavComponent,
    AddButtonComponent,
    CommonModule,
    ContractTermBannerComponent,
    NgxMatSelectSearchModule,
    CreateEditDialogComponent,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      deps: [KeycloakService, Sentry.TraceService],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'de' },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
